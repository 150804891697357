/* =================================================================== 
 *
 
 *  ------------------------------------------------------------------
 *
 *  TOC:
 *  # base style overrides
 *    ## links
 *  # typography & general theme styles
 *    ## Lists
 *    ## responsive video container
 *    ## floated image
 *    ## tables
 *    ## Spacing 
 *    ## pace.js styles - minimal
 *  # preloader
 *  # forms
 *    ## Style Placeholder Text
 *    ## Change Autocomplete styles in Chrome
 *  # buttons
 *  # additional components
 *    ## alert box
 *    ## additional typo styles
 *    ## skillbars
 *  # reusable and common theme styles
 *    ## display headings
 *  # header styles
 *    ## header logo
 *    ## main navigation
 *    ## mobile menu toggle 
 *  # home
 *    ## home content
 *    ## home sidelinks
 *    ## home scroll
 *    ## home scroll
 *    ## home animations
 *  # about
 *    ## about process
 *  # services
 *    ## services list
 *  # works
 *    ## bricks/masonry
 *  # testimonials
 *  # stats 
 *  # contact 
 *    ## contact social
 *    ## subscribe
 *    ## copyright
 *    ## go to top
 *
 * =================================================================== */


/* ===================================================================
 * # base style overrides
 *
 * ------------------------------------------------------------------- */
html {
  font-size: 10px;
}
@media only screen and (max-width: 400px) {
  html {
    font-size: 9.411764705882353px;
  }
}

html, body {
  height: 100%;
}

body {
  background: #111111;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.765;
  color: #555555;
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------------- 
 * ## links
 * ------------------------------------------------------------------- */
a {
  color: #cc147f;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:active {
  color: #26bfb5;
}

a:hover,
a:active {
  outline: 0;
}


/* ===================================================================
 * # typography & general theme styles
 * 
 * ------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "metropolis-semibold", sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin-top: 6rem;
  margin-bottom: 1.8rem;
}

@media only screen and (max-width:600px) {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    margin-top: 5.1rem;
  }

}

h5, .h5, h6, .h6 {
  margin-top: 4.2rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width:600px) {
  h5, .h5, h6, .h6 {
    margin-top: 3.6rem;
    margin-bottom: 0.9rem;
  }

}

h1, .h1 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

@media only screen and (max-width:600px) {
  h1, .h1 {
    font-size: 3.3rem;
    letter-spacing: -0.07rem;
  }

}

h2, .h2 {
  font-size: 3rem;
  line-height: 1.3;
}

h3, .h3 {
  font-size: 2.4rem;
  line-height: 1.25;
}

h4, .h4 {
  font-size: 2.1rem;
  line-height: 1.286;
}

h5, .h5 {
  font-size: 1.7rem;
  line-height: 1.235;
}

h6, .h6 {
  font-size: 1.4rem;
  line-height: 1.286;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
}

p img {
  margin: 0;
}

p.lead {
  font-family: "metropolis-regular", sans-serif;
  font-size: 2.4rem;
  line-height: 1.75;
  margin-bottom: 3.6rem;
  color: rgba(85, 85, 85, 0.95);
}
@media only screen and (max-width: 1200px) {
  p.lead {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 1000px) {
  p.lead {
    font-size: 2.1rem;
  }
}
@media only screen and (max-width: 800px) {
  p.lead {
    font-size: 1.9rem;
  }
}

em, i, strong, b {
  font-size: inherit;
  line-height: inherit;
  font-style: normal;
  font-weight: normal;
}

em, i {
  font-family: "metropolis-italic", sans-serif;
}

strong, b {
  font-family: "metropolis-semibold", sans-serif;
  font-weight: normal;
}

small {
  font-size: 1.2rem;
  line-height: inherit;
}

blockquote {
  margin: 3.9rem 0;
  padding-left: 4.5rem;
  position: relative;
}

blockquote:before {
  content: "\201C";
  font-size: 10rem;
  line-height: 0px;
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-family: arial, sans-serif;
  position: absolute;
  top: 3.6rem;
  left: 0;
}

blockquote p {
  font-family: "metropolis-regular", sans-serif;
  padding: 0;
  font-size: 2.1rem;
  line-height: 1.857;
  color: #111111;
}

blockquote cite {
  display: block;
  font-family: "domine-regular", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1.5;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #626262;
  border: none;
}

abbr {
  font-family: "metropolis-bold", sans-serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
  color: #626262;
}

var, kbd, samp, code, pre {
  font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
  padding: 2.4rem 3rem 3rem;
  background: #f1f1f1;
  overflow-x: auto;
}

code {
  font-size: 1.4rem;
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

pre > code {
  display: block;
  white-space: pre;
  line-height: 2;
  padding: 0;
  margin: 0;
}

pre.prettyprint > code {
  border: none;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
  text-decoration: none;
}

mark {
  background: #ffd900;
  color: #000000;
}

hr {
  border: solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0 0;
  clear: both;
  margin: 2.4rem 0 1.5rem;
  height: 0;
}


/* ------------------------------------------------------------------- 
 * ## Lists
 * ------------------------------------------------------------------- */
ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  display: list-item;
}

ol,
ul {
  margin-left: 1.7rem;
}

ul li {
  padding-left: 0.4rem;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0.6rem 0 0.6rem 1.7rem;
}

ul.disc li {
  display: list-item;
  list-style: none;
  padding: 0 0 0 0.8rem;
  position: relative;
}
ul.disc li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #cc147f;
  position: absolute;
  left: -17px;
  top: 11px;
  vertical-align: middle;
}

dt {
  margin: 0;
  color: #cc147f;
}

dd {
  margin: 0 0 0 2rem;
}


/* ------------------------------------------------------------------- 
 * ## responsive video container
 * ------------------------------------------------------------------- */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* ------------------------------------------------------------------- 
 * ## floated image
 * ------------------------------------------------------------------- */
img.pull-right {
  margin: 1.5rem 0 0 3rem;
}

img.pull-left {
  margin: 1.5rem 3rem 0 0;
}


/* ------------------------------------------------------------------- 
 * ## tables
 * ------------------------------------------------------------------- */
table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  font-family: "metropolis-regular", sans-serif;
}

th,
td {
  padding: 1.5rem 3rem;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

th {
  color: #000000;
  font-family: "metropolis-semibold", sans-serif;
  font-weight: normal;
}

td {
  line-height: 1.5;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}


/* ------------------------------------------------------------------- 
 * ## Spacing 
 * ------------------------------------------------------------------- */
button,
.btn {
  margin-bottom: 1.2rem;
}

fieldset {
  margin-bottom: 1.5rem;
}

input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.video-container,
.cl-custom-select {
  margin-bottom: 3rem;
}

/* ------------------------------------------------------------------- 
 * ## pace.js styles - minimal
 * ------------------------------------------------------------------- */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #cc147f;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.oldie .pace {
  display: none;
}



/* ===================================================================
 * # preloader
 *
 * ------------------------------------------------------------------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #050505;
  z-index: 800;
  height: 100%;
  width: 100%;
}

.no-js #preloader,
.oldie #preloader {
  display: none;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  padding: 0;
}

#loader:before {
  content: "";
  border-top: 6px solid rgba(255, 255, 255, 0.1);
  border-right: 6px solid rgba(255, 255, 255, 0.1);
  border-bottom: 6px solid rgba(255, 255, 255, 0.1);
  border-left: 6px solid #cc147f;
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* ===================================================================
 * # forms
 *
 * ------------------------------------------------------------------- */
fieldset {
  border: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  display: block;
  height: 6rem;
  padding: 1.5rem 2.4rem;
  border: 0;
  outline: none;
  color: #151515;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 3rem;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cl-custom-select {
  position: relative;
  padding: 0;
}
.cl-custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  margin: 0;
  line-height: 3rem;
  vertical-align: middle;
}
.cl-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}
.cl-custom-select select::-ms-expand {
  display: none;
}
.cl-custom-select::after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  margin-top: -7px;
  pointer-events: none;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

/* IE9 and below */
.oldie .cl-custom-select::after {
  display: none;
}

textarea {
  min-height: 25rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: #000000;
}

label,
legend {
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 0.9rem;
  line-height: 1.714;
  color: #000000;
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label > .label-text {
  display: inline-block;
  margin-left: 1rem;
  font-family: "metropolis-regular", sans-serif;
  font-weight: normal;
  line-height: inherit;
}

label > input[type="checkbox"],
label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: 0.15rem;
}


/* ------------------------------------------------------------------- 
 * ## Style Placeholder Text
 * ------------------------------------------------------------------- */
::-webkit-input-placeholder {
  color: #626262;
}

:-moz-placeholder {
  color: #626262;
  /* Firefox 18- */
}

::-moz-placeholder {
  color: #626262;
  /* Firefox 19+ */
}

:-ms-input-placeholder {
  color: #626262;
}

.placeholder {
  color: #626262 !important;
}


/* ------------------------------------------------------------------- 
 * ## Change Autocomplete styles in Chrome
 * ------------------------------------------------------------------- */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #cc147f;
  transition: background-color 5000s ease-in-out 0s;
}



/* ===================================================================
 * # buttons
 *
 * ------------------------------------------------------------------- */
.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  height: 5.4rem;
  line-height: 5rem;
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  color: #000000;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #c5c5c5;
  border: 0.2rem solid #c5c5c5;
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background-color: #b8b8b8;
  border-color: #b8b8b8;
  color: #000000;
  outline: 0;
}

/* button primary
 * ------------------------------------------------- */
.btn.btn--primary,
button.btn--primary,
input[type="submit"].btn--primary,
input[type="reset"].btn--primary,
input[type="button"].btn--primary {
  background: #cc147f;
  border-color: #cc147f;
  color: #ffffff;
}

.btn.btn--primary:hover,
button.btn--primary:hover,
input[type="submit"].btn--primary:hover,
input[type="reset"].btn--primary:hover,
input[type="button"].btn--primary:hover,
.btn.btn--primary:focus,
button.btn--primary:focus,
input[type="submit"].btn--primary:focus,
input[type="reset"].btn--primary:focus,
input[type="button"].btn--primary:focus {
  background: #b51271;
  border-color: #b51271;
}

/* button modifiers
 * ------------------------------------------------- */
.btn.full-width,
button.full-width {
  width: 100%;
  margin-right: 0;
}

.btn--medium,
button.btn--medium {
  height: 5.7rem !important;
  line-height: 5.3rem !important;
}

.btn--large,
button.btn--large {
  height: 6rem !important;
  line-height: 5.6rem !important;
}

.btn--stroke,
button.btn--stroke {
  background: transparent !important;
  border: 0.2rem solid #000000;
  color: #000000;
}

.btn--stroke:hover,
button.btn--stroke:hover {
  background: #000000 !important;
  border: 0.2rem solid #000000;
  color: #ffffff;
}

.btn--pill,
button.btn--pill {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 1000px !important;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}



/* =================================================================== 
 * # additional components
 *
 * ------------------------------------------------------------------- */

/* ------------------------------------------------------------------- 
 * ## alert box
 * ------------------------------------------------------------------- */
.alert-box {
  padding: 2.1rem 4rem 2.1rem 3rem;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 3px;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
}
.alert-box__close {
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
  cursor: pointer;
}
.alert-box__close.fa {
  font-size: 12px;
}
.alert-box--error {
  background-color: #ffd1d2;
  color: #e65153;
}
.alert-box--success {
  background-color: #c8e675;
  color: #758c36;
}
.alert-box--info {
  background-color: #d7ecfb;
  color: #4a95cc;
}
.alert-box--notice {
  background-color: #fff099;
  color: #bba31b;
}

/* ------------------------------------------------------------------- 
 * ## additional typo styles
 * ------------------------------------------------------------------- */

/* drop cap 
 * ----------------------------------------------- */
.drop-cap:first-letter {
  float: left;
  margin: 0;
  padding: 1.5rem 0.6rem 0 0;
  font-size: 8.4rem;
  font-family: "metropolis-bold", sans-serif;
  font-weight: normal;
  line-height: 6rem;
  text-indent: 0;
  background: transparent;
  color: #000000;
}

/* line definition style 
 * ----------------------------------------------- */
.lining dt,
.lining dd {
  display: inline;
  margin: 0;
}
.lining dt + dt:before,
.lining dd + dt:before {
  content: "\A";
  white-space: pre;
}
.lining dd + dd:before {
  content: ", ";
}
.lining dd + dd:before {
  content: ", ";
}
.lining dd:before {
  content: ": ";
  margin-left: -0.2em;
}

/* dictionary definition style 
 * ----------------------------------------------- */
.dictionary-style dt {
  display: inline;
  counter-reset: definitions;
}
.dictionary-style dt + dt:before {
  content: ", ";
  margin-left: -0.2em;
}
.dictionary-style dd {
  display: block;
  counter-increment: definitions;
}
.dictionary-style dd:before {
  content: counter(definitions, decimal) ". ";
}

/** 
 * Pull Quotes
 * -----------
 * markup:
 *
 * <aside class="pull-quote">
 *		<blockquote>
 *			<p></p>
 *		</blockquote>
 *	</aside>
 *
 * --------------------------------------------------------------------- */
.pull-quote {
  position: relative;
  padding: 2.1rem 3rem 2.1rem 0px;
}
.pull-quote:before,
.pull-quote:after {
  height: 1em;
  position: absolute;
  font-size: 10rem;
  font-family: Arial, Sans-Serif;
  color: rgba(0, 0, 0, 0.25);
}
.pull-quote:before {
  content: "\201C";
  top: -3.6rem;
  left: 0;
}
.pull-quote:after {
  content: "\201D";
  bottom: 3.6rem;
  right: 0;
}
.pull-quote blockquote {
  margin: 0;
}
.pull-quote blockquote:before {
  content: none;
}

/** 
 * Stats Tab
 * ---------
 * markup:
 *
 * <ul class="stats-tabs">
 *		<li><a href="#">[value]<em>[name]</em></a></li>
 *	</ul>
 *
 * Extend this object into your markup.
 *
 * --------------------------------------------------------------------- */
.stats-tabs {
  padding: 0;
  margin: 3rem 0;
}
.stats-tabs li {
  display: inline-block;
  margin: 0 1.5rem 3rem 0;
  padding: 0 1.5rem 0 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.stats-tabs li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.stats-tabs li a {
  display: inline-block;
  font-size: 2.5rem;
  font-family: "metropolis-bold", sans-serif;
  font-weight: normal;
  border: none;
  color: #000000;
}
.stats-tabs li a:hover {
  color: #cc147f;
}
.stats-tabs li a em {
  display: block;
  margin: 0.6rem 0 0 0;
  font-size: 1.4rem;
  font-family: "metropolis-regular", sans-serif;
  color: #626262;
}


/* ------------------------------------------------------------------- 
 * ## skillbars 
 * ------------------------------------------------------------------- */
.skill-bars {
  list-style: none;
  margin: 6rem 0 3rem;
}
.skill-bars li {
  height: 0.6rem;
  background: #c9c9c9;
  width: 100%;
  margin-bottom: 6.9rem;
  padding: 0;
  position: relative;
}
.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "metropolis-semibold", sans-serif;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
}
.skill-bars li .progress {
  background: #000000;
  position: relative;
  height: 100%;
}
.skill-bars li .progress span {
  position: absolute;
  right: 0;
  top: -3.6rem;
  display: block;
  font-family: "metropolis-regular", sans-serif;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1;
  background: #000000;
  padding: 0.6rem 0.6rem;
  border-radius: 3px;
}
.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #000000;
  content: "";
}
.skill-bars li .percent5 {
  width: 5%;
}
.skill-bars li .percent10 {
  width: 10%;
}
.skill-bars li .percent15 {
  width: 15%;
}
.skill-bars li .percent20 {
  width: 20%;
}
.skill-bars li .percent25 {
  width: 25%;
}
.skill-bars li .percent30 {
  width: 30%;
}
.skill-bars li .percent35 {
  width: 35%;
}
.skill-bars li .percent40 {
  width: 40%;
}
.skill-bars li .percent45 {
  width: 45%;
}
.skill-bars li .percent50 {
  width: 50%;
}
.skill-bars li .percent55 {
  width: 55%;
}
.skill-bars li .percent60 {
  width: 60%;
}
.skill-bars li .percent65 {
  width: 65%;
}
.skill-bars li .percent70 {
  width: 70%;
}
.skill-bars li .percent75 {
  width: 75%;
}
.skill-bars li .percent80 {
  width: 80%;
}
.skill-bars li .percent85 {
  width: 85%;
}
.skill-bars li .percent90 {
  width: 90%;
}
.skill-bars li .percent95 {
  width: 95%;
}
.skill-bars li .percent100 {
  width: 100%;
}



/* ===================================================================
 * # reusable and common theme styles
 *
 * ------------------------------------------------------------------- */
.wide {
  max-width: 1400px;
}

.narrow {
  max-width: 800px;
}

.light-gray {
  background-color: #f1f1f1;
}


/* ------------------------------------------------------------------- 
 * ## display headings
 * ------------------------------------------------------------------- */
.display-1 {
  font-family: "domine-bold", sans-serif;
  font-size: 6rem;
  line-height: 1.3;
  letter-spacing: 0;
  color: #000000;
  letter-spacing: -0.03rem;
  margin-top: 0;
  margin-bottom: 4.8rem;
}
.display-1--light {
  color: #ffffff;
}

.subhead {
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.6rem;
  line-height: 1.3125;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  color: #cc147f;
  margin-top: 0;
  margin-bottom: 2.1rem;
}
.subhead::before {
  content: "/ ";
}

/* ------------------------------------------------------------------- 
 * responsive:
 * display headings
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1400px) {
  .display-1 {
    font-size: 5.6rem;
  }
}
@media only screen and (max-width: 1200px) {
  .display-1 {
    font-size: 5.2rem;
  }
}
@media only screen and (max-width: 950px) {
  .display-1 {
    font-size: 4.6rem;
  }
}
@media only screen and (max-width: 800px) {
  .display-1 {
    font-size: 4.2rem;
    margin-bottom: 4.2rem;
  }

  .subhead {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 700px) {
  .display-1 {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 600px) {
  .display-1 {
    font-size: 3.6rem;
  }
}
@media only screen and (max-width: 500px) {
  .display-1 {
    font-size: 3.3rem;
  }

  .subhead {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 400px) {
  .display-1 {
    font-size: 3rem;
  }
}



/* ===================================================================
 * # header styles
 *
 * ------------------------------------------------------------------- */
.s-header {
  z-index: 500;
  width: 100%;
  height: 96px;
  background-color: black;
  position: fixed;
  color: white;
  
}

/* -------------------------------------------------------------------
 * ## header logo
 * ------------------------------------------------------------------- */
.header-logo {
  z-index: 501;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 110px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
}
.header-logo a {
  display: block;
  padding: 0;
  outline: 0;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-logo img {
  width: 200px;
  height: 38px;
}


/* ------------------------------------------------------------------- 
 * ## main navigation 
 * ------------------------------------------------------------------- */
.header-nav {
  z-index: 900;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.3rem;
  line-height: 1.846;
  padding: 3.6rem 3rem 3.6rem 3.6rem;
  height: 100%;
  width: 280px;
  background: #0c0c0c;
  color: rgba(255, 255, 255, 0.25);
  overflow-y: auto;
  overflow-x: auto;
  position: fixed;
  right: 0;
  top: 0;
  visibility: visible;
  
}
.header-nav a,
.header-nav a:visited {
  color: rgba(255, 255, 255, 0.5);
}
.header-nav a:hover,
.header-nav a:focus,
.header-nav a:active {
  color: white;
}
.header-nav h3 {
  font-family: "metropolis-semibold", sans-serif;
  font-size: 11px;
  line-height: 1.363;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  margin-bottom: 4.8rem;
  margin-top: 0.9rem;
  color: #cc147f;
}
.header-nav p {
  margin-bottom: 2.7rem;
}

.header-nav__content {
   background: #0c0c0c;
  position: relative;
  visibility: visible;
  opacity: 1;
  left: 0;
}

.header-nav__list {
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.6rem;
  margin: 3.6rem 0 3rem 0;
  padding: 0 0 1.8rem 0;
  list-style: none;
  counter-reset: ctr;
}
.header-nav__list li {
  border-top: 1px solid rgba(255, 255, 255, 0.04);
  line-height: 4.8rem;
  position: relative;
}
.header-nav__list li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}
.header-nav__list a {
  display: block;
  color: #ffffff;
  position: relative;
  padding-left: 3.5rem;
}
.header-nav__list a::before {
  content: counter(ctr, decimal-leading-zero) ".";
  counter-increment: ctr;
  font-family: "metropolis-regular", sans-serif;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.25);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 3px;
  top: 0;
}
.header-nav__list a:hover::before {
  color: #cc147f;
}

.header-nav__social {
  list-style: none;
  display: inline-block;
  font-size: 1.8rem;
  margin: 0;
}
.header-nav__social li {
  display: inline-block;
  margin-right: 12px;
  padding-left: 0;
}
.header-nav__social li a {
  color: rgba(255, 255, 255, 0.15);
}
.header-nav__social li a:hover,
.header-nav__social li a:focus {
  color: white;
}
.header-nav__social li:last-child {
  margin: 0;
}

.header-nav__close {
  z-index: 800;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 30px;
  border-radius: 3px;
  position: absolute;
  top: 36px;
  right: 30px;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  cursor: pointer;
}
.header-nav__close span::before,
.header-nav__close span::after {
  content: "";
  display: block;
  height: 2px;
  width: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 9px;
  margin-top: -1px;
}
.header-nav__close span::before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.header-nav__close span::after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* menu is open
 * ----------------------------------------------- */
.menu-is-open .header-nav {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-overflow-scrolling: touch;
}
.menu-is-open .header-nav .header-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  left: 0;
}


/* ------------------------------------------------------------------- 
 * ## mobile menu toggle 
 * --------------------
 
----------------------------------------------- */
.menu-button-new {
  position: fixed;
  right: 110px;
  top: 42px;
  width: 48px;
  height: 45px;
  
}
.header-menu-toggle {
  position: fixed;
  right: 110px;
  top: 42px;
  width: 48px;
  height: 45px;
  line-height: 45px;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: #ffffff;
}
.header-menu-toggle:hover,
.header-menu-toggle:focus,
.header-menu-toggle:active {
  color: #cc147f;
  cursor: pointer;
}

.header-menu-icon {
  display: block;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  right: auto;
  bottom: auto;
  background-color: white;
  position: absolute;
  left: 11px;
  top: 50%;
}
.header-menu-icon::before,
.header-menu-icon::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: inherit;
  position: absolute;
  left: 0;
}
.header-menu-icon::before {
  top: -9px;
}
.header-menu-icon::after {
  bottom: -9px;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * header
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1600px) {
  .header-logo {
    left: 60px;
  }

  .header-menu-toggle {
    right: 60px;
  }
}
@media only screen and (max-width: 800px) {
  .header-logo {
    left: 40px;
  }

  .header-menu-toggle {
    right: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header {
    height: 90px;
    top: 12px;
  }

  .header-logo {
    left: 30px;
  }
  .header-logo img {
    width: 180px;
    height: 34px;
  }

  .header-menu-toggle {
    right: 25px;
    top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .header-menu-toggle::before {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .s-header {
    top: 6px;
  }

  .header-logo {
    left: 25px;
  }
  .header-logo img img {
    width: 141px;
    height: 27px;
  }

  .header-menu-toggle {
    top: 24px;
    right: 20px;
  }
}


/* ===================================================================
 * # home
 *
 * ------------------------------------------------------------------- */
.s-home {
  width: 100%;
  height: 100%;
  min-height: 786px;
  background-color: transparent;
  position: relative;
  display: table;
  background-image: url(./images/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.s-home .shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: linear-gradient(to right, black 0%, black 20%, transparent 100%);
}
.s-home::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background-color: #000000;
  
}

.no-js .s-home {
  background: #000000;
}


/* ------------------------------------------------------------------- 
 * ## home content
 * ------------------------------------------------------------------- */
.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding-bottom: 15.6rem;
  overflow: hidden;
  position: relative;
}
.home-content h1 {
  font-family: "domine-bold", sans-serif;
  font-size: 9rem;
  line-height: 1.3;
  margin-top: 0;
  color: #ffffff;
}
.home-content h1 + p {
  font-family: "metropolis-light", sans-serif;
  font-size: 3.8rem;
  line-height: 1.421;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2.4rem;
  padding-left: 10rem;
  position: relative;
}
.home-content h1 + p::before {
  content: "";
  display: block;
  width: 6.5rem;
  height: 1px;
  background-color: #cc147f;
  position: absolute;
  left: 6px;
  top: 2.7rem;
}

.home-content__main {
  padding-top: 15rem;
  padding-right: 20rem;
  position: relative;
}


/* ------------------------------------------------------------------- 
 * ## home sidelinks
 * ------------------------------------------------------------------- */
.home-sidelinks {
  list-style: none;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.4rem;
  line-height: 1.714;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  margin: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
}
.home-sidelinks li {
  display: block;
  padding: 0;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.home-sidelinks li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.home-sidelinks li::before {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  background-color: #cc147f;
  border-radius: 50%;
  position: absolute;
  top: 3rem;
  left: 2rem;
}
.home-sidelinks a {
  display: block;
  padding: 2.1rem 15rem 2.1rem 4rem;
  color: #ffffff;
}
.home-sidelinks span {
  display: block;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  letter-spacing: 0;
}
.home-sidelinks li:hover {
  background-color: rgba(0, 0, 0, 0.5);
}


/* ------------------------------------------------------------------- 
 * ## home scroll
 * ------------------------------------------------------------------- */
.home-scroll {
  height: 4.8rem;
  position: absolute;
  right: 110px;
  bottom: 6.6rem;
}
.home-scroll__text {
  display: inline-block;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 11px;
  line-height: 4.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff;
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  right: 7.5rem;
}
.home-scroll__icon {
  display: block;
  height: 4.8rem;
  width: 4.8rem;
  background-color: #ffffff;
  background-image: url(./images//icons/icon-arrow-down.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10px 15px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}
.home-scroll:hover .home-scroll__text {
  border-bottom: 1px solid #ffffff;
}

.no-svg .home-scroll__icon {
  background-image: url(./images//icons/png/icon-arrow-down.png);
}


/* ------------------------------------------------------------------- 
 * ## home scroll
 * ------------------------------------------------------------------- */
.home-social {
  list-style: none;
  font-size: 14px;
  line-height: 4.8rem;
  margin: 0;
  color: #ffffff;
  position: absolute;
  bottom: 2.6rem;
  right: 0;
}
.home-social a {
  color: #ffffff;
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.home-social a:hover {
}
.home-social i {
  display: none;
}
.home-social .home-social-title {
  font-family: "metropolis-semibold", sans-serif;
  padding-right: 4rem;
  margin-right: 0.8rem;
  position: relative;
}
.home-social .home-social-title::after {
  display: block;
  content: "";
  width: 2.4rem;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  right: 0;
  top: 50%;
}
.home-social li {
  display: inline-block;
  position: relative;
  margin-right: 0.6rem;
}
.home-social li a {
  display: block;
}
.home-social li:last-child {
  margin-right: 0;
}

/* animate .home-content__main
 * ------------------------------------------------------------------- */
html.cl-preload .home-content__main {
  opacity: 0;
}

html.cl-loaded .home-content__main {
  animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

html.no-csstransitions .home-content__main {
  opacity: 1;
}


/* ------------------------------------------------------------------- 
 * ## home animations
 * ------------------------------------------------------------------- */
/* fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 150%, 0);
    -ms-transform: translate3d(0, 150%, 0);
    transform: translate3d(0, 150%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 150%, 0);
    -ms-transform: translate3d(0, 150%, 0);
    transform: translate3d(0, 150%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* fade out */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -150%, 0);
    -ms-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -150%, 0);
    -ms-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
  }
}

/* ------------------------------------------------------------------- 
 * responsive:
 * home
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1600px) {
  .home-content h1 {
    font-size: 8.8rem;
  }
  .home-content h1 + p {
    font-size: 3.6rem;
  }

  .home-content__main {
    padding-top: 18rem;
  }

  .home-sidelinks {
    font-size: 1.3rem;
  }

  .home-scroll {
    right: 60px;
  }

  .home-social {
    left: 60px;
  }
}
@media only screen and (max-width: 1500px) {
  .home-content h1 {
    font-size: 8.6rem;
  }

  .home-content__main {
    max-width: 1100px;
  }
}
@media only screen and (max-width: 1400px) {
  .home-content h1 {
    font-size: 7.8rem;
  }
  .home-content h1 + p {
    font-size: 3rem;
  }
  .home-content h1 + p::before {
    top: 2.4rem;
  }

  .home-content__main {
    max-width: 1000px;
  }

  .home-sidelinks a {
    padding: 2.1rem 12rem 2.1rem 4rem;
  }
}
@media only screen and (max-width: 1200px) {
  .home-content h1 {
    font-size: 7.2rem;
  }
  .home-content h1 + p {
    font-size: 2.4rem;
    padding-right: 2rem;
    padding-left: 8rem;
  }
  .home-content h1 + p::before {
    width: 5.5rem;
    top: 1.8rem;
  }
  .home-content br {
    display: none;
  }

  .home-content__main {
    max-width: 850px;
  }

  .home-sidelinks a {
    padding: 1.8rem 6rem 1.8rem 4rem;
  }
}
@media only screen and (max-width: 1000px) {
  .home-content__main {
    max-width: 650px;
    padding-right: 0;
  }

  .home-sidelinks {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .home-content h1 {
    font-size: 7rem;
  }
}
@media only screen and (max-width: 800px) {
  .home-content h1 {
    font-size: 6.5rem;
  }

  .home-content__main {
    max-width: 580px;
  }

  .home-scroll {
    right: 40px;
  }
  .home-scroll__text {
    display: none;
  }

  .home-social {
    left: 40px;
  }
}
@media only screen and (max-width: 700px) {
  .home-content h1 {
    font-size: 5.5rem;
  }
  .home-content h1 + p {
    font-size: 2.2rem;
    padding-right: 0;
  }
  .home-content h1 + p::before {
    top: 1.6rem;
  }

  .home-content__main {
    max-width: 480px;
  }
}
@media only screen and (max-width: 600px) {
  .home-content h1 {
    font-size: 5.4rem;
  }
  .home-content h1 + p {
    padding-top: 3rem;
    padding-left: 0;
  }
  .home-content h1 + p::before {
    left: 0;
    top: 0;
  }

  .home-content__main {
    width: auto;
    padding-left: 35px;
    padding-right: 35px;
  }

  .home-social {
    left: 30px;
    bottom: 6rem;
    bottom: 6rem;
  }
  .home-social i {
    display: inline;
  }
  .home-social .home-social-text {
    display: none;
  }

  .home-scroll {
    right: 30px;
    bottom: 6rem;
  }
  .home-scroll__icon {
    height: 4.2rem;
    width: 4.2rem;
  }
}
@media only screen and (max-width: 500px) {
  .home-content h1 {
    font-size: 5rem;
  }
  .home-content h1 + p {
    padding-top: 2.8rem;
  }

  .s-home {
    min-height: 642px;
  }
}
@media only screen and (max-width: 450px) {
  .home-content h1 {
    font-size: 4.6rem;
  }
  .home-content h1 + p {
    padding-top: 2.4rem;
  }
}
@media only screen and (max-width: 400px) {
  .s-home {
    min-height: 630px;
  }

  .home-content h1 {
    font-size: 4.2rem;
  }
  .home-content h1 + p {
    padding-top: 2.1rem;
  }

  .home-content__main {
    padding-left: 25px;
    padding-right: 25px;
  }

  .home-social {
    font-size: 13px;
    left: 25px;
  }
  .home-social .home-social-title {
    padding-right: 0.6rem;
  }
  .home-social .home-social-title::after {
    display: none;
  }

  .home-scroll {
    right: 25px;
  }
}



/* ===================================================================
 * # about
 *
 * ------------------------------------------------------------------- */
.s-about {
  padding-top: 18rem;
  padding-bottom: 18rem;
  background-color: #ffffff;
  position: relative;
}


/* ------------------------------------------------------------------- 
 * ## about process
 * ------------------------------------------------------------------- */
.process {
  margin-top: 4.2rem;
  position: relative;
}
.process__vline-left,
.process__vline-right {
  display: block;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
}
.process__vline-left {
  top: 0;
  left: calc(20px + 24px);
}
.process__vline-right {
  top: 0;
  left: calc(50% + 20px + 24px);
}
.process h4 {
  font-size: 2.8rem;
  line-height: 1.5;
  margin-top: 0;
  position: relative;
}
.process__col {
  position: relative;
  min-height: 24.6rem;
}
.process__col::before {
  content: attr(data-item);
  display: block;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 2.1rem;
  text-align: center;
  color: #ffffff;
  background-color: #26bfb5;
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 0 18px #ffffff;
  position: absolute;
  left: 20px;
  top: 0;
}
.process__text {
  margin-left: 7.5rem;
  padding-right: 30px;
}


/* ------------------------------------------------------------------- 
 * responsive:
 * about
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  .s-about {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .process__vline-left {
    left: calc(15px + 22px);
  }
  .process__vline-right {
    left: calc(50% + 15px + 22px);
  }
  .process h4 {
    font-size: 2.6rem;
  }
  .process__col::before {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 1.8rem;
    left: 15px;
  }
  .process__text {
    margin-left: 6.8rem;
    padding-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .s-about {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .process {
    margin-top: 3rem;
  }
  .process__vline-right {
    display: none;
  }
  .process__col {
    min-height: 0;
  }
  .process__col::before {
    top: -3px;
  }
}
@media only screen and (max-width: 600px) {
  .process__vline-left {
    left: calc(10px + 22px);
  }
  .process__col::before {
    top: -3px;
    left: 10px;
  }
  .process h4 {
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 400px) {
  .s-about {
    padding-top: 9.6rem;
  }

  .process h4 {
    font-size: 2.2rem;
    padding-left: 5.4rem;
    margin-bottom: 1.8rem;
  }
  .process__vline-left {
    display: none;
  }
  .process__col {
    margin-bottom: 4.2rem;
  }
  .process__col::before {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 1.6rem;
    top: -3px;
    left: 0;
  }
  .process__text {
    margin-left: 0;
  }
}



/* ===================================================================
 * # services
 *
 * ------------------------------------------------------------------- */
.s-services {
  padding-top: 18rem;
  padding-bottom: 18rem;
  color: #555555;
  position: relative;
}


/* ------------------------------------------------------------------- 
 * ## services list
 * ------------------------------------------------------------------- */
 .service-p {
   line-height: 30px;
 }

.services-list {
  margin-top: 6rem;
}
.services-list .service-item {
  margin-bottom: 1.5rem;
}
.services-list .service-item h3 {
  margin-top: 0;
  margin-bottom: 1.8rem;
  margin-top: 1.8rem;
}
.services-list .service-icon {
  color: #26bfb5;
  font-size: 4.8rem;
}


/* ------------------------------------------------------------------- 
 * responsive:
 * services
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  .s-services {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .services-list [class*="col-"]:nth-child(2n + 1) {
    padding-right: 30px;
  }
  .services-list [class*="col-"]:nth-child(2n + 2) {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 800px) {
  .s-services {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .services-list {
    margin-top: 3rem;
  }
  .services-list .service-icon {
    font-size: 4.5rem;
  }
  .services-list [class*="col-"]:nth-child(n) {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 600px) {
  .services-list [class*="col-"]:nth-child(n) {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .s-services {
    padding-top: 9.6rem;
  }

  .services-list [class*="col-"]:nth-child(n) {
    padding: 0;
  }
}



/* ===================================================================
 * # works
 *
 * ------------------------------------------------------------------- */
.s-works {
  background: #ffffff;
  padding: 18rem 0 24rem;
  position: relative;
}


/* ------------------------------------------------------------------- 
 * ## bricks/masonry
 * ------------------------------------------------------------------- */
.masonry-wrap {
  margin-top: 6rem;
  max-width: none;
  width: 1500px;
}

.masonry:after {
  content: "";
  display: table;
  clear: both;
}
.masonry .grid-sizer,
.masonry__brick {
  width: 600px;
}
.masonry__brick {
  float: left;
  padding: 0;
  padding-right: 30px;
  padding-bottom: 30px;
  border-width: 2px;
  border: #000000;
}

.item-folio {
  position: relative;
  overflow: hidden;
}

.item-folio__thumb img {
  vertical-align: top;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.item-folio__thumb a {
  display: block;
}
.item-folio__thumb a::before {
  z-index: 1;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  content: "";
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.item-folio__thumb a::after {
  z-index: 1;
  content: "...";
  font-family: georgia, serif;
  font-size: 3rem;
  color: #000000;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}

.item-folio__text {
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 3.6rem;
  padding: 0 3rem;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.item-folio__title {
  color: #000000;
  font-size: 1.8rem;
  margin: 0;
}

.item-folio__cat {
  color: black;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.item-folio__caption {
  display: none;
}

.item-folio__project-link {
  z-index: 500;
  display: block;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  background-color: #000000;
  padding: 0.6rem 1.2rem;
  position: absolute;
  top: 3rem;
  left: 3rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.item-folio__project-link::before {
  display: block;
  content: "";
  height: 1px;
  width: 3rem;
  background-color: white;
  position: absolute;
  top: 50%;
  left: -3rem;
}
.item-folio__project-link:hover,
.item-folio__project-link:focus,
.item-folio__project-link:active {
  background-color: #cc147f;
  color: #ffffff;
}

/* on hover */
.item-folio:hover .item-folio__thumb a::before {
  opacity: 1;
  visibility: visible;
}
.item-folio:hover .item-folio__thumb a::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.item-folio:hover .item-folio__thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.item-folio:hover .item-folio__project-link {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.item-folio:hover .item-folio__text {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}


/* ------------------------------------------------------------------- 
 * responsive:
 * works
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1500px) {
  .masonry-wrap {
    width: 1200px;
  }

  .masonry .grid-sizer,
  .masonry__brick {
    width: 400px;
  }
}
@media only screen and (max-width: 1350px) {
  .masonry-wrap {
    width: 1158px;
  }

  .masonry .grid-sizer,
  .masonry__brick {
    width: 386px;
  }
}
@media only screen and (max-width: 1300px) {
  .masonry-wrap {
    width: 990px;
  }

  .masonry .grid-sizer,
  .masonry__brick {
    width: 330px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-works {
    padding: 15rem 0 22.2rem;
  }
}
@media only screen and (max-width: 1100px) {
  .masonry-wrap {
    width: 90%;
    max-width: 800px;
  }

  .masonry .grid-sizer,
  .masonry__brick {
    width: 50%;
  }
}
@media only screen and (max-width: 800px) {
  .s-works {
    padding: 12rem 0 21rem;
  }

  .masonry-wrap {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 600px) {
  .masonry .grid-sizer,
  .masonry__brick {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .s-works {
    padding: 9.6rem 0 21rem;
  }

  .masonry-wrap {
    width: auto;
  }
}



/* ===================================================================
 * # testimonials
 *
 * ------------------------------------------------------------------- */
.testimonials-header {
  text-align: center;
  margin-top: 7.2rem;
}

.testimonials {
  font-size: 1.8rem;
  line-height: 2;
  margin-top: 4.2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}

.testimonials__slider {
  position: relative;
}
.testimonials__slider .slick-slide {
  outline: none;
}
.testimonials__slider .slick-dots {
  display: block;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 3rem 0 0 0;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 0;
}
.testimonials__slider .slick-dots li {
  display: inline-block;
  width: 27px;
  height: 27px;
  margin: 0;
  padding: 9px;
  cursor: pointer;
}
.testimonials__slider .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  line-height: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}
.testimonials__slider .slick-dots li button:hover,
.testimonials__slider .slick-dots li button:focus {
  outline: none;
}
.testimonials__slider .slick-dots li.slick-active button,
.testimonials__slider .slick-dots li:hover button {
  background: #000000;
}

.testimonials__slide {
  position: relative;
  padding-top: 4.5rem;
  padding-left: 12rem;
  padding-right: 3rem;
}
.testimonials__slide::before {
  content: "";
  display: block;
  width: 1px;
  height: 3.9rem;
  position: absolute;
  left: calc(7.8rem / 2);
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.testimonials__avatar {
  width: 7.8rem;
  height: 7.8rem;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 4.8rem;
  outline: none;
}

.testimonials__author {
  margin-top: 0rem;
  font-family: "metropolis-bold", sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  color: #000000;
}
.testimonials__author span {
  display: block;
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  color: rgba(85, 85, 85, 0.7);
  text-transform: none;
  letter-spacing: 0;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * testimonials
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1100px) {
  .testimonials {
    font-size: 1.7rem;
  }

  .testimonials__slide {
    padding-left: 9.5rem;
  }
  .testimonials__slide::before {
    left: calc(6.6rem / 2);
  }

  .testimonials__avatar {
    width: 6.6rem;
    height: 6.6rem;
  }
}
@media only screen and (max-width: 600px) {
  .testimonials-header h1 {
    font-size: 2.7rem;
  }

  .testimonials {
    margin-top: 3rem;
  }

  .testimonials__slide {
    padding: 13.2rem 0 0;
    text-align: center;
  }
  .testimonials__slide::before {
    left: 50%;
  }

  .testimonials__avatar {
    left: 50%;
    margin-left: -3.3rem;
  }
}



/* ===================================================================
 * # stats 
 *
 * ------------------------------------------------------------------- */
.s-stats {
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
  background-color: #050505;
  background-image: url(./images//stats-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.s-stats::before {
  display: block;
  content: "";
  background-color: #050505;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.stats {
  text-align: center;
}
.stats__col {
  margin: 5.4rem 0;
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.stats__count {
  font-family: "metropolis-light", sans-serif;
  font-size: 9.2rem;
  line-height: 1;
  color: #ffffff;
  padding-bottom: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.stats__count::after {
  display: block;
  content: "";
  height: 1px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
}
.stats h5 {
  font-family: "metropolis-regular", sans-serif;
  font-size: 1.8rem;
  line-height: 1.333;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 1.8rem;
  position: relative;
}


/* ------------------------------------------------------------------- 
 * responsive:
 * stats
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  .stats {
    max-width: 900px;
  }
  .stats__col {
    margin: 3rem 0;
  }
  .stats__count {
    font-size: 9rem;
  }
}
@media only screen and (max-width: 600px) {
  .stats__count {
    font-size: 8.4rem;
  }
}
@media only screen and (max-width: 400px) {
  .s-stats {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .stats__count {
    font-size: 8.2rem;
  }
}



/* ===================================================================
 * # contact 
 *
 * ------------------------------------------------------------------- */
.s-contact {
  padding-top: 21rem;
  padding-bottom: 10.2rem;
  background-color: #111111;
  color: rgba(255, 255, 255, 0.3);
  position: relative;
}
.s-contact a {
  color: #ffffff;
  border-bottom: 1px solid transparent;
  display: inline-block;
}
.s-contact a:hover,
.s-contact a:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-main {
  margin-top: 14.4rem;
  margin-bottom: 4.8rem;
}

.contact-email {
  display: inline-block;
  font-family: "metropolis-light", sans-serif;
  font-size: 5.4rem;
  line-height: 1.222;
  color: #ffffff;
  padding-top: 3.6rem;
  position: relative;
}
.contact-email::before {
  content: "";
  display: block;
  height: 1px;
  width: 60px;
  background-color: #cc147f;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-number {
  font-family: "metropolis-light", sans-serif;
  font-size: 3rem;
  line-height: 1.7;
  display: block;
}

.contact-address {
  font-family: "metropolis-regular", sans-serif;
  font-size: 2.8rem;
  line-height: 1.607;
  margin-top: 2.4rem;
}


/* ------------------------------------------------------------------- 
 * ## contact social
 * ------------------------------------------------------------------- */
.contact-social {
  list-style: none;
  display: inline-block;
  margin: 0 0 0 0.3rem;
  font-size: 3rem;
}
.contact-social li {
  margin-right: 15px;
  padding-left: 0;
  display: inline-block;
}
.contact-social li a {
  color: rgba(255, 255, 255, 0.3);
  border: none !important;
}
.contact-social li a:hover,
.contact-social li a:focus {
  color: #ffffff;
}
.contact-social li:last-child {
  margin: 0;
}


/* ------------------------------------------------------------------- 
 * ## subscribe
 * ------------------------------------------------------------------- */
.contact-subscribe {
  margin: 0;
  padding: 0;
  position: relative;
}

.mc-form {
  margin-top: 2.1rem;
  width: 100%;
}
.mc-form input[type="email"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 5.4rem;
  padding: 1.2rem 150px 1.2rem 20px;
  margin-bottom: 1.8rem;
  border: none;
}
.mc-form input[type="email"]:focus {
  color: #ffffff;
}
.mc-form input[type="email"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}
.mc-form input[type="email"]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
  /* Firefox 18- */
}
.mc-form input[type="email"]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
  /* Firefox 19+ */
}
.mc-form input[type="email"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}
.mc-form input[type="email"].placeholder {
  color: rgba(255, 255, 255, 0.2) !important;
}
.mc-form input[type="submit"] {
  color: #ffffff;
  background: #cc147f;
  border-color: #cc147f;
  padding: 0 15px;
  position: absolute;
  top: 0;
  right: 0;
}
.mc-form label {
  color: #ffffff;
  font-family: "metropolis-semibold", sans-serif;
  font-size: 1.3rem;
  padding: 0 2rem;
}
.mc-form label i {
  padding-right: 6px;
}


/* ------------------------------------------------------------------- 
 * ## copyright
 * ------------------------------------------------------------------- */
.cl-copyright {
  margin-top: 2.1rem;
}

.cl-copyright span {
  font-size: 15px;
  display: inline-block;
}
.cl-copyright span::after {
  content: "|";
  display: inline-block;
  padding: 0 1rem 0 1.2rem;
  color: rgba(255, 255, 255, 0.1);
}
.cl-copyright span:last-child::after {
  display: none;
}


/* ------------------------------------------------------------------- 
 * ## go to top
 * ------------------------------------------------------------------- */
.cl-go-top {
  z-index: 600;
  position: absolute;
  bottom: 10.8rem;
  right: 105px;
}
.cl-go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: black;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cl-go-top a i {
  font-size: 21px;
  line-height: inherit;
}
.cl-go-top a:hover,
.cl-go-top a:focus {
  background-color: #ffffff;
  color: #000000;
}
.cl-go-top::before {
  content: "";
  display: block;
  width: 1px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -115px;
  left: 50%;
}
.cl-go-top::after {
  content: "back to top";
  display: inline-block;
  width: 120px;
  font-size: 1.2rem;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(255, 255, 255, 0.15);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  position: absolute;
  left: calc(30px + 15px);
  top: -165px;
}

.menu-is-open .cl-go-top {
  display: none;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * contact
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1600px) {
  .cl-go-top {
    right: 55px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-contact {
    padding-top: 15rem;
  }

  .contact-main {
    margin-top: 13.2rem;
  }

  .contact-email {
    font-size: 5rem;
  }

  .contact-number {
    font-size: 2.6rem;
  }

  .contact-address {
    font-size: 2.4rem;
  }

  .contact-social {
    font-size: 2.8rem;
  }

  .cl-copyright {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 1000px) {
  .contact-email {
    font-size: 4.2rem;
  }

  .contact-number {
    font-size: 2.4rem;
  }

  .contact-address {
    font-size: 2.1rem;
  }

  .contact-social {
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 800px) {
  .s-contact {
    padding-top: 12rem;
    padding-bottom: 9rem;
  }

  .contact-secondary,
  .cl-copyright {
    padding-right: 7.8rem;
  }

  .contact-secondary {
    margin-bottom: 1.5rem;
  }

  .contact-email {
    font-size: 3.6rem;
  }

  .contact-number {
    font-size: 2rem;
  }

  .contact-address {
    font-size: 2rem;
  }

  .cl-go-top {
    right: 35px;
    bottom: 9rem;
  }
  .cl-go-top a {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }
  .cl-go-top a i {
    font-size: 18px;
  }
  .cl-go-top::after {
    font-size: 1.1rem;
    left: calc(24px + 15px);
  }
}
@media only screen and (max-width: 600px) {
  .s-contact {
    padding-bottom: 6rem;
  }

  .contact-main {
    margin-top: 12rem;
  }

  .contact-email {
    font-size: 3rem;
  }

  .contact-number {
    font-size: 1.8rem;
  }

  .contact-address {
    font-size: 1.8rem;
  }

  .contact-social {
    font-size: 2rem;
  }

  .cl-go-top {
    right: 25px;
    bottom: 7.2rem;
  }
}
@media only screen and (max-width: 480px) {
  .contact-subscribe {
    margin-top: 3rem;
  }

  .mc-form input[type="email"],
  .mc-form label {
    text-align: center;
  }
  .mc-form input[type="email"] {
    padding: 1.2rem 20px 1.2rem;
  }
  .mc-form input[type="submit"] {
    position: static;
    width: 100%;
    margin-bottom: 1.8rem;
  }

  .cl-copyright {
    margin-top: 0;
  }
}
@media only screen and (max-width: 400px) {
  .s-contact {
    padding-top: 9.6rem;
    padding-bottom: 3rem;
  }

  .contact-main {
    margin-top: 9.6rem;
  }

  .contact-secondary {
    padding: 0;
  }

  .contact-email {
    font-size: 2.4rem;
  }

  .contact-number {
    font-size: 1.6rem;
  }

  .cl-copyright {
    margin-top: 9rem;
    padding: 0;
    text-align: center;
  }

  .cl-copyright span {
    font-size: 14px;
  }
  .cl-copyright span::after {
    display: none;
  }

  .cl-go-top {
    bottom: 12rem;
    right: 50%;
    margin-right: -24px;
  }
  .cl-go-top::before,
  .cl-go-top::after {
    display: none;
  }
}

/*# sourceMappingURL=main.css.map */
